import SheffieldHallamLogo from "public/customers/shu.webp";
import ComcastLogo from "public/customers/comcast.webp";
import HpLogo from "public/customers/hp.webp";
import TrainingWorldLogo from "public/customers/training-world.webp";
import Microsoft from "public/customers/microsoft.webp";
import OnxCanadaLogo from "public/customers/onx-canada.webp";
import ProvidenceLogo from "public/customers/providence.webp";
import XcelEnergyLogo from "public/customers/xcel-energy.webp";
import VmwareLogo from "public/customers/vmware.webp";
import CiscoLogo from "public/customers/cisco.webp";
import SaitLogo from "public/customers/sait.webp";
import CogentLogo from "public/customers/cogent.webp";
import PaloAltoNetworksLogo from "public/customers/palo-alto.webp";
import ErpEnergyLogo from "public/customers/erp-energy.webp";
import CologixLogo from "public/customers/cologix.webp";
import ExecLogo from "public/customers/580-exec.webp";
import BtLogo from "public/customers/bt.webp";
import RedHatLogo from "public/customers/red-hat.webp";
import McdonaldLogo from "public/customers/mcdonald.webp";
import ATTLogo from "public/customers/att.webp";
import LighthouseLogo from "public/customers/lighthouse.webp";
import DeloitteLogo from "public/customers/deloitte.webp";
import ShiLogo from "public/customers/shi.webp";
import CitadelLogo from "public/customers/citadel.webp";
import FlipkartLogo from "public/customers/flipkart.webp";
import Ls2ecTranningLogo from "public/customers/ls2ec.webp";
import EroEnergyLogo from "public/customers/euroclear.webp";
import AbsoluteItLogo from "public/customers/absolute-it.webp";

export const CUSTOMER_LIST = [
  {name: "Sheffield Hallam", path: SheffieldHallamLogo},
  {name: "COMCAST", path: ComcastLogo},
  {name: "Hp", path: HpLogo},
  {name: "Training-world 360", path: TrainingWorldLogo},
  {name: "Microsoft", path: Microsoft},
  {name: "Onx-Canada", path: OnxCanadaLogo},
  {name: "Providence", path: ProvidenceLogo},
  {name: "Xcel Energy", path: XcelEnergyLogo},
  {name: "Vmware", path: VmwareLogo},
  {name: "Cisco", path: CiscoLogo},
  {name: "Sait", path: SaitLogo},
  {name: "Cogent", path: CogentLogo},
  {name: "Palo-alto Networks", path: PaloAltoNetworksLogo},
  {name: "Erp-Energy", path: ErpEnergyLogo},
  {name: "Cologix", path: CologixLogo},
  {name: "580-Exec", path: ExecLogo},
  {name: "Bt", path: BtLogo},
  {name: "Red Hat", path: RedHatLogo},
  {name: "Mcdonald", path: McdonaldLogo},
  {name: "AT&T", path: ATTLogo},
  {name: "Lighthouse", path: LighthouseLogo},
  {name: "Deloitte", path: DeloitteLogo},
  {name: "Shi", path: ShiLogo},
  {name: "Citadel", path: CitadelLogo},
  {name: "Flipkart", path: FlipkartLogo},
  {name: "ls2ec Tranning", path: Ls2ecTranningLogo},
  {name: "Ero Energy", path: EroEnergyLogo},
  {name: "Absolute-It", path: AbsoluteItLogo},
];
