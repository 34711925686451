"use client";
import {useHubspotForm} from "next-hubspot";
import {Box, Stack, Spinner, Text} from "@chakra-ui/react";
import {sendGTMEvent} from "@next/third-parties/google";

const FormStructure = () => {
  const {loaded, error} = useHubspotForm({
    region: "na1",
    portalId: "43951417",
    formId: "5685c644-7023-4a88-bffd-e66a75b3f7c3",
    target: "#contact-form-container",
    onFormSubmitted: () => {
      if (process.env.VERCEL_ENV === "production") {
        sendGTMEvent({
          event: "lead_submission",
          page_uri: window.location.origin + window.location.pathname,
          form_type: "Contact Us",
        });
      }
    },
  });

  if (error) {
    return <Text>Error</Text>;
  }

  return (
    <Stack
      boxShadow={"md"}
      borderRadius={"8px"}
      p={{base: 4, sm: 6}}
      w={"full"}
      border={"1px solid #eee"}
      _hover={{
        boxShadow: "lg",
      }}
      height={"fit-content"}
      data-cy="section-enquiry-form"
      justifyContent={"center"}
    >
      {!loaded && <Spinner />}
      {loaded && <Box id="contact-form-container"></Box>}
    </Stack>
  );
};

export default FormStructure;
