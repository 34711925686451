"use client";
import {CONTACT_LIST} from "./constants";
import {Stack, Heading, Divider, Text, HStack, Icon} from "@chakra-ui/react";
import Link from "next/link";

const ContactSalesPage = () => {
  return (
    <Stack spacing={5} data-cy="section-contact-sales-form">
      <Stack spacing={1}>
        <Heading color={"brand.100"} data-cy="contact-heading">
          Contact Us
        </Heading>
        <Divider width={"50px"} borderBottomWidth={"2px"} borderBottomColor={"black"} />
      </Stack>
      <Heading data-cy="contact-content">
        We Would Be Happy To Meet You And Learn All About Your Business
      </Heading>
      {CONTACT_LIST.map((contact, index) => {
        return (
          <HStack key={index}>
            <Icon as={contact.icon} color={"brand.100"} fontSize={"20px"} />
            <Text
              as={Link}
              href={contact.path}
              target={contact.isExternal ? "_blank" : ""}
              _hover={{color: "brand.100", textDecor: "underline"}}
              data-cy={`contact-label-${index}`}
            >
              {contact.label}
            </Text>
          </HStack>
        );
      })}
    </Stack>
  );
};

export default ContactSalesPage;
