"use client";
import React from "react";
import {
  Box,
  HStack,
  IconButton,
  VStack,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import {HiArrowLeft, HiArrowRight} from "@onemind-services-llc/react-icons-ng/hi";
import {CUSTOMER_LIST} from "./customers.constants";
import Slider from "react-slick";
import Image, {StaticImageData} from "next/image";
import {SectionDivider} from "../SectionDivider";

type Props = {image: StaticImageData; name: string};

const CustomerCard = (props: Props) => {
  const {image, name} = props;
  return (
    <Box p={4}>
      <Box height={"120px"} position={"relative"} w="full">
        <Image
          alt={name}
          title={name}
          blurDataURL={
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/epARQABAAA//w6wAAAAAElFTkSuQmCC"
          }
          fill
          placeholder="blur"
          src={image}
          sizes="(max-width: 600px) 100vw, 600px"
          style={{objectFit: "contain"}}
        />
      </Box>
    </Box>
  );
};

const CustomCarousel = () => {
  const slider = React.useRef<any>(null);
  const slidesToShow = useBreakpointValue({base: 1, sm: 3, md: 4, lg: 6});
  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    centerMode: false,
    infinite: true,
    pauseOnHover: true,
    slidesToScroll: 1,
    slidesToShow: slidesToShow,
    swipeToSlide: true,
  };
  return (
    <Stack data-cy={"section-customers"}>
      <SectionDivider label="Trusted By" />
      <VStack spacing={4}>
        <Box mx={{sm: "-1rem"}} w="100%">
          <Slider {...settings} ref={slider}>
            {CUSTOMER_LIST.map((customer, index) => {
              return (
                <Box key={index} px="1rem">
                  <CustomerCard image={customer.path} name={customer.name} />
                </Box>
              );
            })}
          </Slider>
        </Box>
        <HStack spacing={6}>
          <IconButton
            aria-label="previous"
            color="brand.100"
            fontSize="30px"
            icon={<HiArrowLeft />}
            onClick={() => slider?.current?.slickPrev()}
            variant={"ghost"}
          />
          <IconButton
            aria-label="next"
            color="brand.100"
            fontSize="30px"
            icon={<HiArrowRight />}
            onClick={() => slider?.current?.slickNext()}
            variant={"ghost"}
          />
        </HStack>
      </VStack>
    </Stack>
  );
};

export default CustomCarousel;
