import {
  FaEnvelopeOpenText,
  FaLocationArrow,
  FaMailBulk,
  FaPhoneAlt,
} from "@onemind-services-llc/react-icons-ng/fa";

export const CONTACT_LIST = [
  {
    icon: FaEnvelopeOpenText,
    label: "sales@cloudmylab.com",
    path: "mailto:sales@cloudmylab.com",
  },
  {
    icon: FaMailBulk,
    label: "support@cloudmylab.com",
    path: "mailto:support@cloudmylab.com",
  },
  {icon: FaPhoneAlt, label: "+1-408-201-9381", path: "tel:+1-408-201-9381"},
  {
    isExternal: true,
    icon: FaLocationArrow,
    label: "11501 Dublin Bvld, Suite#200 Dublin CA 94568",
    path: "https://maps.google.com?q=11501 Dublin Bvld, Suite#200 Dublin CA 94568",
  },
];
