"use client";

import {AbsoluteCenter, Box, Divider, Heading} from "@chakra-ui/react";

type SectionDividerProps = {
  label: string;
  gridColumn?: any;
  padding?: string | number;
  bg?: string;
};

export const SectionDivider = ({
  label,
  padding = "10",
  gridColumn = {},
  bg = "white",
}: SectionDividerProps) => {
  return (
    <Box
      position={"relative"}
      padding={padding}
      my={20}
      width={"100%"}
      gridColumn={gridColumn}
    >
      <Divider style={{borderColor: "gray.500"}} />
      <AbsoluteCenter
        bg={bg}
        px={{
          base: 2,
          sm: 4,
        }}
      >
        <Heading
          as={"h2"}
          fontWeight={"normal"}
          textAlign={"center"}
          fontSize={{
            base: "2xl",
            sm: "3xl",
          }}
          data-cy="section-heading"
        >
          {label}
        </Heading>
      </AbsoluteCenter>
    </Box>
  );
};
